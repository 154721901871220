<template>
  <div class="about">
    <v-main>
      <v-container>
        <v-row justify="center">
          <v-col :cols="12" :md="7">
            <h1>PRIVACY POLICY</h1>
            <p class="p-desc">GradBee is committed to protect its visitor’s and User’s privacy. The following Privacy and Cookie Policy outlines the information by GradBee (which also includes "we", or "us") while ‘User’ refers to all users from students or employer or visitor side. Please read through the following carefully so that you understand our privacy practices.</p>
            <h3>SAFETY OF INFORMATION</h3>
            <p class="p-desc">Safeguarding user’s privacy is very important to us. We take every necessary step to ensure that our user personal information is protected. GradBee collects information from the user when the user voluntarily gives so or register with GradBee. We take every precautionary step to safeguard the user information taken from step one of registering. However, it must be noted that due to uncertainty of the internet we cannot guarantee safety with absolute certainty. GradBee cannot claim that your information is totally safe from activities such as hacking or any other illegal activity, or in the any other event of failure of the equipment or technical problem, though every effort to safeguard information will be made from our side.</p>
            <h3>SHARING OF INFORMATION</h3>
            <p class="p-desc">We also track information from where you are accessing our portal and which device you are using such that we can deliver you better service using this information. Similarly, to serve you better we may use the data provided by you and aggregate it to analyse key indicators, data and parameter. This information won’t pin point to any particular user hence maintaining the privacy of the individual user. We ask you to provide information regarding your location which is used to better understand your location preferences and give better service.</p>
            <p class="p-desc">The personal information you share with GradBee also means you agree to share this information with our team as well as other users and recruiters. GradBee does not guarantee that other users of this website will be as careful with your information as we will be. Therefore, while GradBee only shares your information with the service providers whom you have contacted or worked with, we are not responsible for the use of your information by these service providers and will be subjected to the provider's own privacy policy.</p>
            <h3></h3>
            <p class="p-desc">GradBee may share statistical data with its partners or affiliates to help them market through our website. However this data won’t indicate any individual’s identity. The content displayed by our partners won’t be moderated by us hence the liability of data usage by them by clicking on their link should be discussed with them. You may also receive emails from our partners or third party members which are subjected in interest of your own. However, you may unsubscribe information that seems not useful to the user.</p>
            <h3>COOKIES</h3>
            <p class="p-desc">Cookies would be send by us which will help user in login in future. The user can disable this feature if he/she feels so but we recommend our users to allow it such that we can make the user experience better. Also third party or google may use the data to market with ads based on the website visits.</p>
            <h3>ELIGIBILITY OF USAGE</h3>
            <p class="p-desc">GradBee doesn’t store information of minors under age 14 and GradBee is not meant for users under age 14.</p>
            <p class="p-desc">Working on GradBee is meant for all those users who are currently students and are not minors. GradBee verifies college information from all students on its portal. The information taken in form of college email-Id or Image of college identity card is used only for the purpose of verification and won’t be used anywhere or shared with any third party. GradBee urges every user to provide with correct information. Though this information is checked through security check created by us but we don’t guarantee usage of such checked accounts by someone else. Similarly, falsifying information by use of forging or any such techniques deems illegal according to the Indian law hence such activities, if brought to notice, will be dealt inside the premise of law. Also, GradBee doesn’t removes accounts of all those students who graduate from their respective colleges but GradBee doesn’t encourage any such user to participate to work on projects through our portal. However, if someone uses the portal after graduating it will be on mutual consent of the person offering the project though we don’t encourage such activity.</p>
            <h3>TIMELY CHANGES IN PRIVACY POLICY</h3>
            <p class="p-desc">We may make changes to our privacy policies from time to time and keep users updated with the changes. It is user’s responsibility to keep track of the changes on this page. We will send users updates on changes through email provided to us whenever necessary.</p>
            <h3>IN CASE OF ANY QUERY RELATED TO YOUR ACCOUNT, YOU CAN CONTACT US AT PRIVACY@GRADBEE.COM</h3>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
export default {
  metaInfo () {
    return {
      title: 'Privacy Policy | Gradbee',
      meta: [{
        vmid: 'description',
        name: 'description',
        content: 'Gradbee is committed to protect its visitor’s and User’s privacy. The following Privacy and Cookie Policy outlines the information by GradBee (which also includes "we", or "us") while ‘User’ refers to all users from students or employer or visitor side.'
      }]
    }
  }
}
</script>

<style scoped lang="scss">
  .p-desc {
    font-size: .85rem;
  }
</style>
